import { postRequest, getRequest } from './index'
import { SRCURL } from '@/systemConfiguration/index'
// 仓库管理分页
export const wmsWarehousePage = (data, successCallback, failureCallback) => {
  postRequest('/wms/warehouse/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 仓库管理新增和修改
export const warehouseUpdateAndAdd = (data, successCallback, failureCallback) => {
  postRequest(data.id ? '/wms/warehouse/update' : '/wms/warehouse/add', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取商城出库的详情
export const detailOutTakeGoodsBill = (id, successCallback, failureCallback) => {
  getRequest(`${SRCURL}/trade/take/goods/bill/detailOutTakeGoodsBill/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 商城出库提交
export const goodsBillOutTakeGoodsBill = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/trade/take/goods/bill/outTakeGoodsBill`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 仓库管理下拉
export const warehouseList = (warehouseName, successCallback, failureCallback) => {
  getRequest('/wms/warehouse/list2?warehouseName=' + warehouseName, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 仓库管理起禁用
export const warehouseEnableOrDisable = (data, successCallback, failureCallback) => {
  postRequest('/wms/warehouse/enableOrDisable', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 库存管理
// 库存分页
export const inventoryPage = (data, successCallback, failureCallback) => {
  data.state = '01' // 数据上加上01   判断出数据都大于0的
  postRequest('/wms/inventory/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 根据字段查询库存  商城的  根据库存ID进行查询
export const pageCheckLocationDetailInfo = (inventoryId, successCallback, failureCallback) => {
  postRequest(`/wms/inventory/pageCheckLocationDetailInfo/${inventoryId}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 根据字段查询库存
export const inventoryPpageByOutCheckLocationNo = (data, successCallback, failureCallback) => {
  data.state = '01'
  postRequest('/wms/inventory/pageByOutCheckLocationNo', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 库存详情
export const inventoryDetail = (data, successCallback, failureCallback) => {
  postRequest('/wms/inventory/detail', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 入库管理
// 入库分页
export const inventoryInPage = (data, successCallback, failureCallback) => {
  postRequest('/wms/inventoryIn/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 新增入库
export const addInventoryIn = (data, successCallback, failureCallback) => {
  postRequest('/wms/inventoryIn/in', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 入库模板导出
export const inventoryInExport = (data, successCallback, failureCallback) => {
  postRequest('/wms/inventoryIn/export', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 入库模板导入
export const inventoryInImport = (data, successCallback, failureCallback) => {
  postRequest('/wms/inventoryIn/import', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 入库详情
export const inventoryInDetail = (data, successCallback, failureCallback) => {
  postRequest('/wms/inventoryIn/detail', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 出库管理
// 出库分页
export const inventoryOutPage = (data, successCallback, failureCallback) => {
  postRequest('/wms/inventoryOut/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 出库新增
export const inventoryOut = (data, successCallback, failureCallback) => {
  postRequest('/wms/inventoryOut/out', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 提货出库
export const deliveryInfoOutBoundDelivery = (data, successCallback, failureCallback) => {
  postRequest('deliveryInfo/outBoundDelivery', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 出库明细
export const inventoryOutDetail = (data, successCallback, failureCallback) => {
  postRequest('/wms/inventoryOut/detail', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 过户管理
// 过户分页
export const inventoryTransferPage = (data, successCallback, failureCallback) => {
  postRequest('/wms/inventoryTransfer/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 过户详情
export const inventoryTransferDetails = (data, successCallback, failureCallback) => {
  postRequest('/wms/inventoryTransfer/detail', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 过户新增
export const inventoryTransferAdd = (data, successCallback, failureCallback) => {
  postRequest('/wms/inventoryTransfer/transfer', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 移库管理
// 移库分页
export const inventoryMovePage = (data, successCallback, failureCallback) => {
  postRequest('/wms/inventoryMove/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 移库新增
export const inventoryMove = (data, successCallback, failureCallback) => {
  postRequest('/wms/inventoryMove/move', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 盘点管理
// 盘点分页
export const inventoryCheckPage = (data, successCallback, failureCallback) => {
  postRequest('/wms/inventoryCheck/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 盘点新增
export const inventoryCheck = (data, successCallback, failureCallback) => {
  postRequest('/wms/inventoryCheck/check', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 提货流水分页
export const deliveryInfoPage = (data, successCallback, failureCallback) => {
  postRequest('/deliveryInfo/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取商城提单的分页
export const pagePlatScmTakeGoodsBill = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/trade/take/goods/bill/pagePlatScmTakeGoodsBill`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 提货单-详情
export const tradeTakeGoodsBillDetail = (data, successCallback, failureCallback) => {
  getRequest(`${SRCURL}/trade/take/goods/bill/detail/${data}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 提货单-获取提单吗
export const getTakeCode = (id, successCallback, failureCallback) => {
  getRequest(`${SRCURL}/trade/take/goods/bill/getTakeCode/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 根据仓库查找库区及货位
export const warehouseGetYarnAndArea = (data, successCallback, failureCallback) => {
  getRequest(`/wms/warehouse/getYarnAndArea?id=${data}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
